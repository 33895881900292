import { h } from 'preact'
import classNames from 'clsx'

import { FOOTER_LINKS } from '../../../config'
import { getDisplayName } from '../../utils'
import { SIDE_RIGHT } from '../../../constants'
import VerticalList, { VerticalListEntry } from '../../molecule/VerticalList'
import IconLink from '../../atom/IconLink'

import styles from './index.module.scss'

const SiteFooter = ({ className, children }) => (
  <footer className={classNames(className, styles['o-site-footer'])}>
    {children}

    <VerticalListFooterEntry external sticky side={SIDE_RIGHT}>
      {FOOTER_LINKS.map(({ id, label, ...props }) => (
        <VerticalListEntry key={id}>
          <IconLink {...props}>{label}</IconLink>
        </VerticalListEntry>
      ))}
    </VerticalListFooterEntry>
  </footer>
)

const VerticalListFooterEntry = AsSiteFooterEntry(VerticalList)

export const SiteFooterEntry = AsSiteFooterEntry('section', 'SiteFooterEntry')

export function AsSiteFooterEntry(Component, displayName) {
  const WrappedComponent = (props) => {
    const {
      className,
      withOffset,
      external,
      sticky,
      children,
      ...rest
    } = props

    return (
      <Component
        {...rest}
        className={buildFooterEntryClassName({
          className,
          withOffset,
          external,
          sticky,
        })}
      >
        {children}
      </Component>
    )
  }

  WrappedComponent.displayName = displayName || `AsSiteFooterEntry(${getDisplayName(Component)})`

  return WrappedComponent
}

function buildFooterEntryClassName({ className, withOffset, external, sticky }) {
  return classNames(
    className,
    styles['o-site-footer__entry'],
    {
      [styles['o-site-footer__entry--offset']]: withOffset,
      [styles['o-site-footer__entry--external']]: external,
      [styles['o-site-footer__entry--sticky']]: sticky,
    },
  )
}

export default SiteFooter
